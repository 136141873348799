import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import i18n from '../locales/i18n';
import styled from 'styled-components';
import Helmet from 'react-helmet';

// Layout component
import Layout from '../components/layouts/Layout';
import PrimaryButton from '../components/PrimaryButton';
import { XSvgComponent } from '../components/svgs/x';

const ContactLayout = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 7% 0% 7% 0%;
`;

const ContactContent = styled.div`
    display:flex;
    flex-direction:column;
    width:19%;

    @media screen and (max-width: 680px) {
        width:45%;
        margin-bottom:5%;
     }
`;

const ContactContent2 = styled.div`
    display:flex;
    flex-direction:column;
    width:19%;
    padding-left:6%;

    @media screen and (max-width: 680px) {
        width:45%;
        margin-bottom:5%;
    }
`;
const Title = styled.div`
    display: flex;
    justify-content:center;
`;

const SecondTitle = styled.div`
    font-size:22px;
    margin:2% 0%;
    flex-wrap: wrap;
    text-align:center;
    font-weight: 200;
`;

const FormContent = styled.div`
    display:flex;
    flex-direction:row;
    width:100%;
    justify-content: center;
`;

const Column1 = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    width : 50%;

    @media (min-width: 320px) and (max-width: 767px) {
        display: none;
        width: 0%;
    }
`;

const Column2 = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    width : 50%;
    margin-left: 8%;

    @media (min-width: 320px) and (max-width: 767px) {
        width:100%;
    }
`;

const SectionText = styled.div`
    padding-bottom: 8%;
`;

const TitleCell = styled.h2`
    font-size: 16px;
    font-weight: 300;
`;

const TextCell = styled.p`
    font-size: 14px;
    font-weight: 200;
`;

const TitleCity = styled.p`
    font-size:16px;
    font-weight:300;
    margin:0% 0%;
`;

const Address = styled.p`
    margin:0px;
    font-size:14px;
    line-height:19px;
    font-weight:200;
`;

const Phone = styled.p`
    font-size:14px;
    font-weight:200;
    color:#2FAAE1;
    margin:0% 0%;
`;

const TitleContact = styled.h2`
    font-size: 30px;
    font-weight: 200;
    margin:7% 0%;
    display : flex;
    justify-content: center;
`;

const Contact = styled.div`
    display:flex;
    flex-direction: row;

    @media (min-width: 320px) and (max-width: 1024px) {
        flex-direction: column;
    }
`;

const ValidationMessageCell = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const FormCell = styled.form`
    display: flex;
    flex-direction: column;
    width:100%;

    @media (min-width: 320px) and (max-width: 900px) {
        display:flex;
        justify-content: center;
    }
`;

const FormField = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom:10%;

    @media (min-width: 320px) and (max-width: 480px) {
        flex-direction: column;
    }
`;

const LabelCell = styled.label`
    margin-bottom:2%;
    font-size:19px;
    font-weight:200;
`;

const InputCell = styled.input`
    font-size: 16px;
    color: #ffffff;
    border-color: transparent;
    background: transparent;
    outline: none;
    border-bottom: 2px solid white;
    padding-top: 3%;
    font-family: 'Montserrat';
    font-weight: 400;
`;

const TextAreacell = styled.textarea`
    font-size: 16px;
    color: #ffffff;
    border-color: transparent;
    background: transparent;
    outline: none;
    border-bottom: 2px solid white;
    padding-top: 3%;
    font-family: 'Montserrat';
    font-weight: 400;
`;

const FormLabelName = styled.div`
    width:50%;
    display:flex;
    flex-direction:column;
    margin-right:2%;

    @media (min-width: 320px) and (max-width: 767px) {
        width:100%;
        margin-bottom: 10%;
        margin-right: 0%;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        margin-bottom: 5%;
    }
`;

const FormLabelEmail = styled.div`
    width: 100%;
    display:flex;
    flex-direction:column;
    margin-bottom: 10%;

    @media (min-width: 320px) and (max-width: 767px) {
        width:100%;
        margin-bottom: 20%;
        margin-left: 0%;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        margin-bottom: 15%;
    }
`;

const FormLabelPhone = styled.div`
    width: 100%;
    display:flex;
    flex-direction:column;
    margin-bottom: 10%;

    @media (min-width: 320px) and (max-width: 767px) {
        width:100%;
        margin-bottom: 20%;
        margin-left: 0%;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        margin-bottom: 15%;
    }
`;

const FormLabelCompany = styled.div`
    width: 100%;
    display:flex;
    flex-direction:column;
    margin-bottom: 10%;

    @media (min-width: 320px) and (max-width: 767px) {
        width:100%;
        margin-bottom: 20%;
        margin-left: 0%;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        margin-bottom: 15%;
    }
`;

const FormLabelInterest = styled.div`
width: 100%;
display:flex;
flex-direction:column;
margin-bottom: 5%;

@media (min-width: 320px) and (max-width: 767px) {
    width:100%;
    margin-bottom: 20%;
    margin-left: 0%;
}

@media (min-width: 768px) and (max-width: 1024px) {
    margin-bottom: 15%;
}
`;

const DropdownSelect = styled.select`
    display:flex;
    flex-direction:row;
    background-color : #ffffff;
    color: #0850AF;
    width : 100%;
    max-width: 250px;
    font-size: 16px;
    margin: 2% 0% 6% 0%;
    height: 46%;
    border-color: #ffffff;
    border-radius: 3px;
    border-width: 12px;
    cursor: pointer;
    hover: none;
    font-family: 'Montserrat';

    @media (min-width: 320px) and (max-width: 767px) {
        max-width: 100%;
    }
`;

const FormFieldMessage = styled.div`
    display:flex;
    flex-direction:column;
`;

const ContactButton = styled.div`
    margin: 6% 0% 0% 0%;
    display:flex;
    flex-direction:row;
    justify-content: flex-end;
`;

const SocialIconsLayout = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin:6% 0%;
`;

const SocialIconsContent = styled.a`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 14%;
    margin-right: 6%;
    color:white;
`;

const SocialPicture = styled.img`
    margin-right:6%;
`;

const CircleIconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    margin-right: 6%;
    color: #ffffff;
    box-sizing: border-box;

    > svg {
        width: 20px;
        height: 20px;
    }
`;

const encode = (data) => Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');

class ContactPage extends Component {

    state = {
        isSending: false,
        displayValidationMessage: false,
        name: '',
        phone: '',
        email: '',
        lastName: '',
        company: '',
        interest: 'mobile',
        message: ''
    };

    handleSubmit = e => {
        e.preventDefault();

        const {
            name, email, phone, lastName, message, company, interest
        } = this.state;

        this.setState({isSending: true});

        fetch('https://api.getwemap.com/v3.0/contacts', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                name,
                email,
                phone,
                lastName,
                company,
                interest,
                purpose: message,
                language: i18n.language
            })
        })
            .then(() => {
                this.setState({
                    isSending: false,
                    displayValidationMessage: true,
                    name: '',
                    phone: '',
                    email: '',
                    lastName: '',
                    company: '',
                    interest: 'mobile',
                    message: ''
                });

                if (typeof window !== 'undefined') {
                    window.gtag('event', 'form send', {
                        event_category: 'form',
                        event_label: 'contact'
                    });
                }
            });
    };

    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    reset = () => {
        this.setState({
            isSending: false,
            displayValidationMessage: false,
            name: '',
            phone: '',
            email: '',
            lastName: '',
            company: '',
            interest: 'mobile',
            message: ''
        });
    };

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                <Helmet defer={false}>
                    <title>{t('META_TITLE_CONTACT')}</title>
                    <meta name="description"
                        content={t('META_DESCRIPTION_CONTACT')}/>
                </Helmet>
                <Layout>
                    <div className="wemap-page-index">
                        <Title className="wemap-page-index-title"
                            dangerouslySetInnerHTML={{ __html: t('TITLE') }}></Title>
                        <SectionText>
                            <SecondTitle>{t('DESCRIPTION_GO_TO')}</SecondTitle>
                            <SecondTitle>{t('DESCRIPTION_DEMO')}</SecondTitle>
                        </SectionText>

                        {
                            this.state.displayValidationMessage && (
                                <ValidationMessageCell>
                                    <span>{t('TITLE_MESSAGE_SENT')}</span>
                                    <br />
                                    <PrimaryButton
                                        onClick={this.reset}
                                        backgroundColor="#ffffff"
                                        color="#227ee6"
                                        borderColor="#ffffff"
                                        hoverColor="#ffffff"
                                        hoverBorderColor="##ffffff"
                                        titleButton="Retour"
                                    />
                                </ValidationMessageCell>
                            )
                        }

                        {!this.state.displayValidationMessage && (
                            <Contact>
                                <FormContent>
                                    <Column1>
                                        <SectionText>
                                            <TitleCell>{t('TITLE_GET_BACK')}</TitleCell>
                                            <TextCell>{t('DESCRIPTION_UNDERSTAND')}</TextCell>
                                        </SectionText>
                                        <SectionText>
                                            <TitleCell>{t('TITLE_SCHEDULE')}</TitleCell>
                                            <TextCell>{t('DESCRIPTION_PRESENT')}</TextCell>
                                        </SectionText>
                                    </Column1>

                                    <Column2>
                                        <FormCell onSubmit={this.handleSubmit}>
                                            <FormField>
                                                <FormLabelName>
                                                    <LabelCell for="name">{t('META_TITLE_NAME')}</LabelCell>
                                                    <InputCell
                                                        id="name"
                                                        type="text"
                                                        name="name"
                                                        required
                                                        onChange={this.handleChange}
                                                        value={this.state.name}
                                                    />
                                                </FormLabelName>

                                                <FormLabelName>
                                                    <LabelCell for="lastName">{t('META_TITLE_LAST_NAME')}</LabelCell>
                                                    <InputCell
                                                        id="lastName"
                                                        type="text"
                                                        name="lastName"
                                                        onChange={this.handleChange}
                                                        value={this.state.lastName}
                                                    />
                                                </FormLabelName>
                                            </FormField>

                                            <FormLabelPhone>
                                                <LabelCell for="phone">{t('META_TITLE_PHONE')}</LabelCell>
                                                <InputCell
                                                    id="phone"
                                                    type="tel"
                                                    name="phone"
                                                    onChange={this.handleChange}
                                                    value={this.state.phone}
                                                />
                                            </FormLabelPhone>

                                            <FormLabelEmail>
                                                <LabelCell for="email">{t('META_TITLE_EMAIL')}</LabelCell>
                                                <InputCell
                                                    id="email"
                                                    type="email"
                                                    name="email"
                                                    required
                                                    onChange={this.handleChange}
                                                    value={this.state.email}
                                                />
                                            </FormLabelEmail>

                                            <FormLabelCompany>
                                                <LabelCell for="email">{t('META_TITLE_COMPANY')}</LabelCell>
                                                <InputCell
                                                    id="company"
                                                    type="company"
                                                    name="company"
                                                    required
                                                    onChange={this.handleChange}
                                                    value={this.state.company}
                                                />
                                            </FormLabelCompany>
                                            <FormLabelInterest>
                                                <LabelCell for="interest">{t('META_TITLE_INTEREST')}</LabelCell>
                                                <DropdownSelect
                                                    id="interest"
                                                    name="interest"
                                                    onChange={this.handleChange}
                                                    value={this.state.interest}>
                                                    <option value="mobile">{t('META_DROPDOWN_MOBILE')}</option>
                                                    <option value="navigation">{t('META_DROPDOWN_NAVIGATION')}</option>
                                                    <option value="indoor">{t('META_DROPDOWN_INDOOR')}</option>
                                                    <option value="ar">{t('META_DROPDOWN_AR')}</option>
                                                    <option value="other">{t('META_DROPDOWN_OTHER')}</option>
                                                </DropdownSelect>
                                            </FormLabelInterest>

                                            <FormFieldMessage>
                                                <LabelCell for="message">Message</LabelCell>
                                                <TextAreacell
                                                    id="message"
                                                    name="message"
                                                    required
                                                    onChange={this.handleChange}
                                                    value={this.state.message}
                                                />
                                            </FormFieldMessage>
                                            <ContactButton>
                                                <PrimaryButton titleButton={t('SEND_MESSAGE')}
                                                    disabled={this.state.isSending}
                                                    backgroundColor="#ffffff"
                                                    color="#227ee6"
                                                    borderColor="#ffffff"
                                                    hoverColor="#ffffff"
                                                    hoverBorderColor="##ffffff"
                                                    type="submit"
                                                />
                                            </ContactButton>
                                        </FormCell>
                                    </Column2>
                                </FormContent>
                            </Contact>
                        )}


                        <TitleContact style={{ marginBottom: '10%' }}>{t('TITLE_CONTACT_FORM')}</TitleContact>

                        <hr className="line" />

                        <ContactLayout>
                            <ContactContent>
                                <TitleCity className="wemap-container-contact-title">{t('Wemap Montpellier')}</TitleCity>
                                <Address>{t('Parc Club du Millénaire Batiment 23, 34000 Montpellier')}</Address>
                                <Phone className="wemap-container-contact-content-phone">{t('+33 7 68 74 42 34')}</Phone>
                            </ContactContent>

                            <ContactContent2>
                                <TitleCity className="wemap-container-contact-title">{t('Wemap Paris')}</TitleCity>
                                <Address>{t('100 rue La Fayette 75010 Paris France')}</Address>
                                <Phone className="wemap-container-contact-content-phone">{t('+33 7 68 74 42 34')}</Phone>
                            </ContactContent2>
                        </ContactLayout>

                        <hr className="line" />
                        <SocialIconsLayout>
                            <SocialIconsContent rel="noopener"
                                aria-label="Link to Facebook"
                                href="https://www.facebook.com/getwemap"
                                target="_blank">
                                <SocialPicture src="/images/contact/facebook.svg"
                                    alt="Facebook" />
                                <p className="desktop">Facebook</p>
                            </SocialIconsContent>

                            <SocialIconsContent rel="noopener"
                                aria-label="Link to X"
                                href="https://x.com/getwemap"
                                target="_blank">
                                <CircleIconContainer>
                                    <XSvgComponent />
                                </CircleIconContainer>
                                <p className="desktop">X</p>
                            </SocialIconsContent>

                            <SocialIconsContent rel="noopener"
                                aria-label="Link to Medium"
                                href="https://blog.getwemap.com"
                                target="_blank">
                                <SocialPicture src="/images/contact/medium.svg"
                                    alt="Medium" />
                                <p className="desktop">Medium</p>
                            </SocialIconsContent>

                            <SocialIconsContent rel="noopener"
                                aria-label="Link to Linkedin"
                                href="https://www.linkedin.com/company/maaap"
                                target="_blank">
                                <SocialPicture src="/images/contact/linkedin.svg"
                                    alt="Linkedin" />
                                <p className="desktop">Linkedin</p>
                            </SocialIconsContent>
                        </SocialIconsLayout>
                        <hr style={{ marginTop: '0%' }}
                            className="line" />

                    </div>
                </Layout>
            </React.Fragment>
        );
    }
}

ContactPage.propTypes = {
    data: PropTypes.object,
    t: PropTypes.func
};

export default withTranslation('contactPage')(ContactPage);
